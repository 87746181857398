//
//  Zero theme - Main javascript code
//

Zero = function() {
    this.__init__();
};

Zero.prototype = {
    __init__: function() {
	var self = this;
	this.sidebar = document.getElementById("sidebar");

	// Add the toggle element (so it'll only appear if Javascript is
	// enabled
	this.toggle = document.createElement('a');
	this.toggle.id = 'toggle-sidebar';
	this.toggle.href = '#toggle';
	this.sidebar.appendChild(this.toggle);

	this.toggle.addEventListener("click", (event) => {
	    self.toggleSidebar(event);
	});

	// Add the dark/light mode override controls (again, they'll only
	// appear if Javascript is enabled)
	icons = document.getElementById("header-icons");

	darkMode = document.createElement('a');
	darkMode.id = 'dark-mode';
	darkMode.title = 'Dark Mode';
	darkMode.href = '#dark-mode';
	darkMode.className = "icon fa fa-moon";
	darkModeLabel = document.createElement('span');
	darkModeLabel.className = "label";
	darkModeLabel.textContent = "Dark Mode";
	darkMode.appendChild(darkModeLabel);
	darkModeLi = document.createElement('li');
	darkModeLi.appendChild(darkMode);

	darkMode.addEventListener("click", (event) => {
	    self.toggleDarkMode(event);
	});

	icons.appendChild(darkModeLi);

	// Hook some events to stop animations from happening and to deal
	// with viewport size changes
	this.autoHideState = null

	window.addEventListener('load', (event) => {
	    self.onLoad(event);
	});

	window.addEventListener('orientationchange', (event) => {
	    self.onOrientationChange(event);
	});

	window.addEventListener('fullscreenchange', (event) => {
	    self.onFullScreenChange(event);
	});

	var resizeTimer;
	window.addEventListener('resize', (event) => {
	    self.onResize(event);
	    window.clearTimeout(resizeTimer);
	    resizeTimer = window.setTimeout(() => {
		self.onResizeEnded();
	    }, 100);
	});

	// Set-up the submenus
	this.submenus = document.querySelectorAll("ul.menu li .submenu");
	this.hideSubmenus();
	this.submenus.forEach((submenu) => {
	    submenu.addEventListener('click', (event) => {
		event.preventDefault();
		event.stopPropagation();
		self.toggleSubmenu(submenu);
	    });
	});
    },

    removeClass: function(obj, className) {
	var newClasses = new Array();
	obj.className.split(/\s+/).forEach((cls) => {
	    if (cls != className) {
		newClasses.push(cls);
	    }
	});
	obj.className = newClasses.join(' ');
    },

    addClass: function(obj, className) {
	var found = false;
	obj.className.split(/\s+/).forEach((cls) => {
	    if (cls == className) {
		found = true;
	    }
	});
	if (!found) {
	    obj.className += ' ' + className;
	}
    },

    toggleClass: function(obj, className) {
	var found = false;
	var newClasses = new Array();
	obj.className.split(/\s+/).forEach((cls) => {
	    if (cls == className) {
		found = true;
	    } else {
		newClasses.push(cls);
	    }
	});
	if (!found) {
	    obj.className += ' ' + className;
	} else {
	    obj.className = newClasses.join(' ');
	}
    },

    hasClass: function(obj, className) {
	return obj.className.split(/\s+/).indexOf(className) != -1;
    },

    onLoad: function(event) {
	// Enable animations
	this.removeClass(document.body, "is-loading");
	this.autoHideSidebar();
    },

    onResize: function(event) {
	// Disable animations
	this.addClass(document.body, "is-resizing");
	this.autoHideSidebar();
    },

    onOrientationChange: function(event) {
	this.autoHideSidebar();
    },

    onFullScreenChange: function(event) {
	this.autoHideSidebar();
    },

    onResizeEnded: function(event) {
	// Re-enable animations
	this.removeClass(document.body, "is-resizing");
    },

    autoHideSidebar: function() {
	if (window.matchMedia('screen').matches) {
	    if (window.matchMedia('(max-width: 1280px)').matches) {
		if (this.autoHideState != 'hidden') {
		    this.hideSidebar();
		    this.autoHideState = 'hidden';
		}
	    } else {
		if (this.autoHideState != 'shown') {
		    this.showSidebar();
		    this.autoHideState = 'shown';
		}
	    }
	}
    },

    toggleSidebar: function(event) {
	event.preventDefault();
	event.stopPropagation();

	this.toggleClass(this.sidebar, "inactive");
    },

    showSidebar: function() {
	this.removeClass(this.sidebar, "inactive");
    },

    hideSidebar: function() {
	this.addClass(this.sidebar, "inactive");
    },

    showSubmenu: function(submenu) {
	this.hideSubmenus();
	this.removeClass(submenu, "closed");
	this.addClass(submenu, "open");
    },

    hideSubmenu: function(submenu) {
	this.removeClass(submenu, "open");
	this.addClass(submenu, "closed");
    },

    toggleSubmenu: function(submenu) {
	if (this.hasClass(submenu, "open")) {
	    this.hideSubmenu(submenu);
	} else {
	    this.showSubmenu(submenu);
	}
    },

    hideSubmenus: function() {
	var self = this;
	this.submenus.forEach((submenu) => {
	    self.hideSubmenu(submenu);
	});
    },

    currentMode: function() {
	html = document.querySelector("html");

	let re = /\b(light|dark)\b/;
	result = re.exec(html.className);
	if (!result) {
	    if (window.matchMedia("(prefers-color-scheme: dark")) {
		return "dark";
	    } else {
		return "light";
	    }
	}
	return result[0];
    },

    toggleDarkMode: function(event) {
	event.preventDefault();
	event.stopPropagation();

	html = document.querySelector("html");

	if (this.currentMode() == "light") {
	    html.className = html.className.replace(/\blight\b/, '') + ' dark';
	} else {
	    html.className = html.className.replace(/\bdark\b/, '') + ' light';
	}
    }

};

var zero = new Zero();
